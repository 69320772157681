import { FaArrowLeft, FaBackspace } from 'react-icons/fa'; // Example icons from 'react-icons'

export default function StartOverButton({ handleReset }) {
    return (
        <button
            className="start-over btn_with_icon"
            type={'button'}
            onClick={handleReset}
        >
            <FaArrowLeft /> Start Over
        </button>
    );
}