// StartScreen.js
import React, { useState } from 'react';
import CustomModal from '../Modal/modal';
import '../App.css';

// StartScreen.js
function StartScreen(props) {
  const [showModal, setShowModal] = useState(false);

  const handleBeginClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOkClick = () => {
    if (props.onBegin) {
      props.onBegin();
    }
  };

  return (
    <div className="start-screen">
      <div className="logo-container">
        <img src="/img/iuris_logo.jpg" alt="logo" />
        <img src="/img/legal_advice.svg" alt="logo" style={{ alignSelf: 'end' }} />
      </div>
      <button className="start-button" onClick={handleBeginClick}>
        Begin
      </button>

      <CustomModal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        onOkClick={handleOkClick}
      />
    </div>
  );
}


export default StartScreen;
