import { FaArrowRight } from 'react-icons/fa'; // Example icons from 'react-icons'

export default function ContinueButton({ handleClick, pageNumber, disabled }) {
    return (
        <button
            type="button"
            onClick={handleClick}
            disabled={disabled}
            className="btn_line_two_continue"
        >
            {pageNumber === 3 ? <> Completed - Continue<FaArrowRight /></> : <> Continue<FaArrowRight /></>}
        </button>
    );
}
