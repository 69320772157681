import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  WhatsappIcon,
  ViberShareButton,
  ViberIcon,

} from 'react-share';

const Header = () => {
  const shareUrl = 'https://iuris.bf-ai.com/';
  const title = 'Check out this awesome website!';

  return (
    <header>
      <div className="header">
        <LinkedinShareButton url={shareUrl} title={title}>
            <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
        <TwitterShareButton url={shareUrl} title={title}>
            <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
        <RedditShareButton url={shareUrl} title={title}>
            <RedditIcon size={32} round={true} />
        </RedditShareButton>
        <FacebookShareButton url={shareUrl} quote={title}>
            <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <EmailShareButton url={shareUrl} subject={title} body="body">
            <EmailIcon size={32} round={true} />
        </EmailShareButton>
        <TelegramShareButton url={shareUrl} title={title}>
            <TelegramIcon size={32} round={true} />
        </TelegramShareButton>
        <FacebookMessengerShareButton url={shareUrl} appId="521270401588372">
            <FacebookMessengerIcon size={32} round={true} />
        </FacebookMessengerShareButton>
        <WhatsappShareButton url={shareUrl} title={title}>
            <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
        <ViberShareButton url={shareUrl} title={title}>
            <ViberIcon size={32} round={true} />
        </ViberShareButton>
      </div>
    </header>
  );
};

export default Header;