import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BookmarkManager = ({ onBookmarkSelect }) => {
  const [bookmarks, setBookmarks] = useState([]);
  const [isBookmarkMenuOpen, setIsBookmarkMenuOpen] = useState(false);

  const fetchBookmarks = () => {
    axios.get('/getBookmarkList')
      .then(response => {
        setBookmarks(response.data);
      })
      .catch(error => console.error('Error fetching bookmarks:', error));
    setIsBookmarkMenuOpen(!isBookmarkMenuOpen);
  };

  const deleteBookmark = (id) => {
    axios.delete('/deleteBookmark', { data: { id: id } })
      .then(() => {
        fetchBookmarks(); // Frissíti a könyvjelzők listáját
        alert('Bookmark deleted successfully.');
      })
      .catch(error => console.error('Error deleting bookmark:', error));
  };

  const handleBookmarkClick = async (id) => {
    onBookmarkSelect(id);
  };

  // useEffect(() => {
  //   fetchBookmarks();
  // }, []);

  return (
    <div className="bookmark-menu">
      <button onClick={() => fetchBookmarks()}>
        <i className="fas fa-bars"></i>
      </button>
      {isBookmarkMenuOpen && (
        <ul>
          {bookmarks.map((bookmark) => (
            <li key={bookmark[0]} onClick={() => handleBookmarkClick(bookmark[0])}> {/* Itt használjuk a [0]-t az id-hez */}
              <div className="tooltip-container">
                {bookmark[1]} {/* feltételezve, hogy a title a tömb második eleme */}
                <span className="tooltip-text">{bookmark[1]}</span>
              </div>
              <button className='bookmark-delete-button' onClick={(e) => {
                e.stopPropagation(); // Megakadályozza az esemény buborékolását
                deleteBookmark(bookmark[0]); // Itt is [0]-t használunk az id-hez
              }}>
                <i className="fas fa-trash"></i>
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default BookmarkManager;
