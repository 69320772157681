import React, { useState } from 'react';
import '../App.css';

function Footer() {

  return (
    <footer className="footer" id="footer">
      <div className="footer-logo">
        <img src="/img/bfailogo.png" alt="Logo" />
      </div>
      <div className="disclaimer">
        <p style={{ fontSize: "12px" }}>Disclaimer</p>
        <p>The information provided by our automated service is intended to assist in the preparation of your legal case. It does not constitute legal advice, nor does it establish a client-lawyer relationship. We strongly recommend consulting with a qualified legal professional before making any decisions based on the information provided.</p>
        <p>We are not responsible for any loss, injury, claim, liability, or damage related to your use of this site or any site linked to this site, whether from errors or omissions in the content of our site or any other linked sites, from the site being down or from any other use of the site.</p>
      </div>
      <div className="contact-info">
        <p className="non-selectable">Phone: &#9742;  +36209999129</p>
        <p className="non-selectable">Phone: &#9742;  +61488663777</p>
        <p>Email: <a href="mailto:info@example.com">info@bf-ai.com</a></p>
        <a href="https://bf-ai.com">Powered by Bright Future AI</a>
      </div>
    </footer>
  );
}

export default Footer;
