import Select from "react-select";

export default function PageOne({ handleChange, countryValue, stateValue, countryOptions, stateOptions, isLoadingCountries, isLoadingStates }) {
    return (
        <div className="page_where_live">
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "20px", padding: "20px" }}>
                <img src="/img/iuris_small_logo.jpg" alt="Logo" />
            </div>
            <div className="selector-container" style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
                <h2>The legal issue related to </h2>
                <Select
                    options={countryOptions}
                    value={countryValue}
                    placeholder={"Country"}
                    className={"select"}
                    onChange={(option) => handleChange("country", option)}
                    isLoading={isLoadingCountries}
                />
                {countryValue && (
                    <Select
                        options={stateOptions}
                        value={stateValue}
                        placeholder={countryValue.regionType || "State"}
                        onChange={(option) => handleChange("state", option)}
                        className={"select"}
                        isLoading={isLoadingStates}
                    />
                )}
            </div>
        </div>
    );
}
