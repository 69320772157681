import gfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';

export default function PageTwo({ handleChange, user_statement, textareaHeight }) {

    return <div>
                    <div className="ai-response">
                <div className="ai-assistant-container">
                    <img src="/img/assist.jpg" alt="Assistant" className="assistant-image" />
                    <div className="speech-bubble">
                        <ReactMarkdown remarkPlugins={[gfm]}>Hi, I’m Miss Iuris, your digital legal assistant. I'm here to help you to clearly articulate your legal concerns. I'll ask questions to understand your situation better and rephrase your statements in a way that's both easy to understand and useful for legal professionals. However, please note that I can't offer direct legal advice. How can I assist you today?</ReactMarkdown>
                    </div>
                </div>
            </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <textarea id="textarea" style = {{height: textareaHeight}} cols={100} onChange={(e) => handleChange('text', e.target.value)} value={user_statement} placeholder="[describe your situation in your own words]"></textarea>
    </div>
    </div>
}