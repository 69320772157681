import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { FaBookmark, FaFileExport, FaEdit, FaCheck, FaSyncAlt, FaSave, FaDownload, FaCopy, FaArrowDown, FaFileDownload, } from 'react-icons/fa';
import React from 'react';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    EmailShareButton,
    EmailIcon,
    RedditShareButton,
    RedditIcon,
    TelegramShareButton,
    TelegramIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    WhatsappShareButton,
    WhatsappIcon,
    ViberShareButton,
    ViberIcon,
} from 'react-share';

export default function PageFour({ text, user_statement, handleChange, loading, response, handleSubmit, handleExport, handleSaveBookmark, isEditable, handleEditOrClarify, regenerateResponse, responses, currentRequest, isRegeneration, textareaHeight,handleEdit }) {

    const shareUrl = 'https://iuris.bf-ai.com/'; // URL of the current page
    const title = user_statement;
    const shareContent = response;

    const handleSocialShare = (platform) => {
        // Logic to share the response content on social media
        // This is pseudocode and will not work without proper implementation
        console.log(`Sharing to ${platform}:`, response);
    };

    return (
        <div className='pagefour'>
            <div>
                <div className={"editable"}>
                    <textarea
                        readOnly={!isEditable}
                        className={isEditable ? '' : 'tb_noteditable'}
                        name={"text"}
                        value={user_statement}
                        cols={100}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        placeholder="[describe your situation in your own words]"
                        id="textarea"
                        style={{ height: textareaHeight }}
                    />
                    <button
                        className={`btn-edit-clarify ${isEditable ? 'btn-clarify' : 'btn-edit'}`}
                        type="button"
                        onClick={handleEdit}
                        disabled={loading}
                        title={isEditable ? "Clarify further" : "Edit"}
                    >
                        {isEditable ? <FaCheck /> : <FaEdit />}
                    </button>
                </div>
                {loading && (
                <div className="spinner-container">
                    <div>
                        <img src="/img/spinner.gif" alt="Loading" className="spinner" />
                        <div className="spinner-text">Please wait, I am processing your request. It can take a while.</div>
                    </div>
                </div>
            )}
            </div>
            {/* <div className='div_question'>{text}</div> */}
            <div className="div_p4_text"><ReactMarkdown remarkPlugins={[gfm]}>{response}</ReactMarkdown></div>
            {/* {!loading && (
                <div className="div_p4_text">
                    {formattedText}
                </div>
            )} */}

            {isRegeneration && (
                <div className='regen-div'>
                    <button
                        onClick={() => regenerateResponse(currentRequest)}
                        title="Think it over again..."
                        className="regenerate-button"
                    >
                        <i className="fas fa-sync-alt"></i>  Regenerate
                    </button>
                </div>
            )}

            {!loading && response && (
                <>
                    <div className="result-div">
                        {/* Left side - Bookmark and Export icons */}
                        <div className='result-buttons'>
                            <button
                                className="icon-button"
                                onClick={handleSaveBookmark}
                                disabled={loading}
                                name="saveBookmark"
                                title="Bookmark response"
                            >
                                <FaCopy />
                            </button>
                            <button
                                className="icon-button"
                                onClick={handleExport}
                                disabled={loading}
                                name="exportResult"
                                title="Export to doc"
                            >
                                <FaFileDownload />
                            </button>
                        </div>


                        <div className="social-icons">
                            <LinkedinShareButton url={shareUrl} title={title} summary={shareContent} className="social-icon linkedin-icon">
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>

                            <TwitterShareButton url={shareUrl} title={title + ' ' + shareContent} className="social-icon twitter-icon">
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>

                            <RedditShareButton url={shareUrl} title={title + ' ' + shareContent} className="social-icon reddit-icon">
                                <RedditIcon size={32} round />
                            </RedditShareButton>

                            <FacebookShareButton url={shareUrl} quote={title + ' ' + shareContent} className="social-icon facebook-icon">
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <EmailShareButton url={shareUrl} subject={title} body={shareContent} className="social-icon email-icon">
                                <EmailIcon size={32} round />
                            </EmailShareButton>

                            <TelegramShareButton url={shareUrl} title={title + ' ' + shareContent} className="social-icon telegram-icon">
                                <TelegramIcon size={32} round />
                            </TelegramShareButton>

                            <FacebookMessengerShareButton url={shareUrl} appId="521270401588372" className="social-icon messenger-icon">
                                <FacebookMessengerIcon size={32} round />
                            </FacebookMessengerShareButton>

                            <WhatsappShareButton url={shareUrl} title={title + ' ' + shareContent} className="social-icon whatsapp-icon">
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>

                            <ViberShareButton url={shareUrl} title={title + ' ' + shareContent} className="social-icon viber-icon">
                                <ViberIcon size={32} round />
                            </ViberShareButton>
                        </div>
                    </div>
                    <hr style={{ width: '80%', margin: '20px auto' }} />
                </>
            )}

            <div className="options-container" style={{ display: "flex", justifyContent: "center" }}>
                <button
                    className="btn_line"
                    type="button"
                    name="summarizeLegalOptions"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    Summarise Legal Options
                </button>
                <button
                    className="btn_line"
                    type="button"
                    name="showApplicablePrecedents"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    Show Applicable Precedents
                </button>
                <button
                    className="btn_line"
                    type="button"
                    name="showLegalReferences"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    Show Legal References
                </button>
            </div>
        </div>
    );
}
