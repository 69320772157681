import { createRoot } from "react-dom/client";
import { useState } from "react";
import { useEffect } from "react";
import PageOne from "./pages/PageOne";
import PageTwo from "./pages/PageTwo";
import ContinueButton from "./Buttons/ContinueButton";
import BackButton from "./Buttons/BackButton";
import axios from "axios";
import PageFour from "./pages/PageFour";
import PageThree from "./pages/PageThree";
import StartOverButton from "./Buttons/StartOverButton";
import "./App.css"
import StartScreen from "./pages/StartScreen";
import Footer from "./pages/footer";
import BookmarkManager from './pages/BookmarkManager';
import Header from "./utilities/Header";
import { FaArrowRight, FaEdit } from 'react-icons/fa';

export default function StartPage() {

  let payload = '';
  let request = '';
  let endpoint = '';

  const [clientRequest, setClientRequest] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [validText, setValidText] = useState(false);
  const [currentRequest, setCurrentRequest] = useState('');

  const [pageNumber, setPageNumber] = useState(1);
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [text, setText] = useState('');
  const [submitted, setSubmitted] = useState(false)
  const [AISum, setAISum] = useState('');
  const [AIResponse, setAIResponse] = useState('');
  const [clarify, setClarify] = useState('');
  const [loading, setLoading] = useState(false);
  const [showStarter, setShowStarter] = useState(true);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [isLoadingCountries, setIsLoadingCountries] = useState(false);
  const [isLoadingStates, setIsLoadingStates] = useState(false);
  const [responses, setResponses] = useState({});
  const [userRequests, setUserRequests] = useState({});
  const [isRegeneration, setIsRegeneration] = useState('');

  const [textareaHeight, setTextareaHeight] = useState('100px');
  const [clientTextAreaHeight, setClientTextAreaHeight] = useState('100px');
  const [language, setLanguage] = useState(''); // Add a state variable for the language
  const [isLanguageSet, setIsLanguageSet] = useState(false); // Add a state variable to track if language is set

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    footerResize();
  }, []);

  const footerResize = () => {
    const footer = document.getElementById('footer');
    const windowWidth = window.innerWidth;
    if (!footer || windowWidth <= 600) return;
    const footerHeight = footer.getBoundingClientRect().height;
    // document.querySelector('.main-content').style.marginBottom = `${footerHeight}px`;
  }
  const fetchCountries = async () => {
    setIsLoadingCountries(true);
    try {
      const response = await axios.get('/getCountries');
      const countries = response.data.map(country => ({ value: country, label: country }));
      setCountryOptions(countries);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
    setIsLoadingCountries(false);
  };

  const fetchStates = async (countryName) => {
    setIsLoadingStates(true);
    try {
      const response = await axios.get('/getRegions', { params: { country_name: countryName } });
      const { list, region_type } = response.data;
      const regions = list.map(region => ({ value: region, label: region }));
      setStateOptions(regions);
      setCountry(prevCountry => ({ ...prevCountry, regionType: region_type }));
    } catch (error) {
      console.error('Error fetching regions:', error);
    }
    setIsLoadingStates(false);
  };

  const autoResize = () => {
    const textarea = document.getElementById('textarea');
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
      setTextareaHeight(`${textarea.scrollHeight}px`);
    }
    const client_textarea = document.getElementById('client-textarea');
    if (client_textarea) {
      client_textarea.style.height = 'auto';
      client_textarea.style.height = `${client_textarea.scrollHeight}px`;
      setClientTextAreaHeight(`${client_textarea.scrollHeight}px`);
    }
  };

  const nextPage = (e) => {
    if (pageNumber === 2) {
      handleSubmit(e)
    }
    setPageNumber(pageNumber => pageNumber + 1)
    if (pageNumber === 4) {
      setTextareaHeight('100px');
      setClientTextAreaHeight('100px');
    }
  }

  const previousPage = (step = 1) => {
    setPageNumber(pageNumber => pageNumber - step)
  }

  const handleChange = (name, value) => {
    switch (name) {
      case "country":
        setCountry(value);
        setState(null);
        if (value) {
          fetchStates(value.value);
        } else {
          setStateOptions([]); // Clear states if no country is selected
        }
        break;
      case "state":
        setState(value);
        break;
      case "text":
        setText(value);
        setValidText(value.trim().split(/\s+/).length >= 4);
        autoResize();
        break;
      case "clientRequest":
        setClientRequest(value);
        autoResize();
        break;
      case "clarify":
        setClarify(value)
        break;
    }
  }

  const handleSubmit = (e, isRegeneration = false) => {
    setLoading(true);
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let buttonId = e.target.name;

    //console.log(buttonId);

    if (buttonId === 'Legal options') {
      buttonId = 'summarizeLegalOptions'
    }
    else if (buttonId === 'Applicable precedents') {
      buttonId = 'showApplicablePrecedents'
    }
    else if (buttonId === 'Legal references') {
      buttonId = 'showLegalReferences'
    }

    if (buttonId === clientRequest && buttonId !== '') {
      buttonId = 'submitClient';
    }


    //console.log(buttonId);

    if (pageNumber === 4) {
      // Check if regeneration is requested
      if (isRegeneration) {
        // Skip the check for existing responses and proceed to generate a new response
      } else {
        // Existing code to handle non-regeneration cases
        if (responses[buttonId]) {
          // If there is already a response and it's not a regeneration request, use the existing response
          if(userRequests[buttonId] && buttonId === "submitClient" && userRequests[buttonId] !== clientRequest){
            
          }
          else{
            setAIResponse(responses[buttonId]);
            setLoading(false);
            setCurrentRequest(buttonId);
            alert('This information has already been retrieved.');
            setIsRegeneration(true);
            return;
          }
        }
      }
    }

    endpoint = '/getResponse';


    let userStatement = text;
    if (buttonId === "clarification") {
      if (isLanguageSet) {
        userStatement = `${language} ${text}`;
      }
    }
    payload = {
      user_statement: userStatement,
      country: country.label ? country.label : '',
      state: state.label ? state.label : '',
      isLanguage: `${isLanguageSet}`, // Add the isLanguageSet boolean to the payload
    };

    if (buttonId === "clarification") {
      payload.user_statement += ". " + clarify;
    } else if (buttonId === "submit") {
      payload.user_statement = AISum;
    } else if (buttonId === "summarizeLegalOptions") {
      endpoint = '/getLegalOptions';
      setCurrentRequest('Legal options');
    } else if (buttonId === "showApplicablePrecedents") {
      endpoint = '/getApplicablePrecedents';
      setCurrentRequest('Applicable precedents');
    } else if (buttonId === "showLegalReferences") {
      endpoint = '/getLegalReferences';
      setCurrentRequest('Legal references');
    } else if (buttonId === "submitClient") {
      endpoint = '/getResponseClient';
      setCurrentRequest(clientRequest);
      payload.request = clientRequest;
    } else if (buttonId === "saveBookmark") {
      endpoint = '/saveBookmark';
      payload.option = currentRequest;
    }
    console.log("Sending to server:", { // Add logging
      endpoint: endpoint,
      payload: payload,
    });

    axios.post(endpoint, payload).then((response) => {
      const responseData = response.data;
      if ('generated_response' in responseData) {
        setText(responseData.RefinedString)
        //console.log(response)
        if (buttonId === "summarizeLegalOptions") {
          setAISum(responseData.RefinedString)
          setAIResponse(responseData.generated_response)
          setText(responseData.user_statement)
        }
        else if (buttonId === "showApplicablePrecedents") {
          setAISum(responseData.RefinedString)
          setAIResponse(responseData.generated_response)
          setText(responseData.user_statement)
        }
        else if (buttonId === "showLegalReferences") {
          setAISum(responseData.RefinedString)
          setAIResponse(responseData.generated_response)
          setText(responseData.user_statement)
        }
        else if (buttonId === "submitClient") {
          setAISum(responseData.RefinedString)
          setAIResponse(responseData.generated_response)
          setText(responseData.user_statement)
        } else {
          setAISum(responseData.generated_response)
        }
      }
      else if ('Response' in responseData) {
        setAIResponse(responseData.Response);
      }
      setResponses(prevResponses => ({
        ...prevResponses,
        [buttonId]: responseData.generated_response || responseData.Response
      }));
      setUserRequests(prevuserRequests => ({
        ...prevuserRequests,
        [buttonId]: payload.request
      }));
      if (!isLanguageSet && response.data.language) {
        setLanguage(response.data.language); // Set the language from the response
        setIsLanguageSet(true); // Set isLanguageSet to true
        console.log("Language set from API response:", response.data.language); // Add logging
      }
      setLoading(false)
      setClarify("")
      autoResize()
      footerResize();
    }).catch(err => {
      console.log(err.response.data)
      setLoading(false);
    })
  }

  const regenerateResponse = (buttonId) => {
    setResponses(prevResponses => {
      const newResponses = { ...prevResponses };
      delete newResponses[buttonId];
      return newResponses;
    });
    setUserRequests(prevuserRequests => {
      const newuserRequests = { ...prevuserRequests };
      delete newuserRequests[buttonId];
      return newuserRequests;
    });
    //console.log(buttonId);

    const event = { target: { name: buttonId } };
    handleSubmit(event, true);
    setIsRegeneration(false);
  };

  const handleExport = (e) => {

    setLoading(true);
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    endpoint = '/exportResult';

    payload = {
      user_statement: text,
      country: country.label ? country.label : '',
      state: state.label ? state.label : '',
      request: currentRequest,
      generated_response: AIResponse
    };

    axios.post(endpoint, payload, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'download.docx';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
          if (fileNameMatch.length === 2) filename = fileNameMatch[1];
        }
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        setLoading(false);
      }).catch((error) => {
        console.error('Download error:', error);
        setLoading(false);
      });
  }

  const handleSaveBookmark = (e) => {
    setLoading(true);
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    endpoint = '/saveBookmark';

    payload = {
      user_statement: text,
      country: country.label ? country.label : '',
      state: state.label ? state.label : '',
      request: currentRequest,
      generated_response: AIResponse
    };

    //console.log(payload);

    axios.post(endpoint, payload)
      .then((response) => {
        const bookmarkName = response.data['title:'].replace(/\"/g, '');
        //console.log(bookmarkName);
        alert(`Bookmark saved successfully as "${bookmarkName}"!`);
        setLoading(false);
        //console.log(response);
      }).catch((error) => {
        console.error('Save Bookmark error:', error);
        setLoading(false);
      });
  }

  const onBookmarkSelect = async (id) => {
    try {
      const response = await axios.post(`/getBookmark`, { id: id });
      const bookmarkData = response.data;

      let bookmark = {};
      if (bookmarkData) {
        bookmark = {
          id: bookmarkData.id,
          date: bookmarkData.saved_date,
          user_statement: bookmarkData.user_statement,
          AI_task: bookmarkData.request,
          AI_response: bookmarkData.generated_response,
          title: bookmarkData.title,
          country: { value: bookmarkData.country, label: bookmarkData.country },
          region: { value: bookmarkData.region, label: bookmarkData.region }
        };
      }
      //console.log(bookmark);
      setText(bookmark.user_statement);
      setAIResponse(bookmark.AI_response);
      setCurrentRequest(bookmark.AI_task);
      setCountry(bookmark.country);
      setState(bookmark.region);
      const regex = "'(.*?)'";
      const matches = bookmark.AI_task.match(regex);
      console.log(bookmark.AI_task);
      if (matches && matches.length > 1 && matches[1] != 'Create a list with short explanation on the legal options' && !bookmark.AI_task.match("show the relevant legal precedents") &&
        !bookmark.AI_task.match("show the relevant legal references")) {
        setClientRequest(matches[1]);
      }
      else {
        setClientRequest('');
      }

      setPageNumber(4);  // Átnavigálás a negyedik oldalra
    } catch (error) {
      console.error('Error fetching bookmark details:', error);
    }
  };



  const handleReset = () => {
    if (window.confirm("This will reset your session, are you sure you want to go restart?")) {
      setPageNumber(1)
      setText("")
      setAISum("")
      setCountry("")
      setState("")
      setClientRequest("")
      setSubmitted(false)
      setAIResponse("")
      setResponses("")
      setLoading(false)
      setTextareaHeight('100px');
      setClientTextAreaHeight('100px');
      setValidText(false);
      setIsLanguageSet(false);
      setIsRegeneration(false);
    }
  }

  const handleBegin = () => {
    setShowStarter(false);
  }

  const handleEditOrClarify = () => {
    if (isEditing) {
      handleSubmit({ target: { name: "clarification" } });
    } else {
      setIsEditing(true);
    }
    setIsEditing(!isEditing);
    autoResize();
  };

  const handleEdit = () => {
    setIsEditing(true);
    setIsEditing(!isEditing);
    autoResize();
  }

  const handleClarify = () => {
    handleSubmit({ target: { name: "clarification" } });
    setIsEditing(!isEditing);
    autoResize();
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing); // Toggle the isEditing state
    if (!isEditing) {
      // If we're going to edit, add flash effect and scroll to textarea
      const textarea = document.getElementById('textarea');
      textarea.classList.add('flash-effect');
      textarea.focus();
      window.scrollTo({ top: 0, behavior: 'smooth' });

      // Remove the flash effect after it finishes
      setTimeout(() => {
        textarea.classList.remove('flash-effect');
      }, 1000); // Assuming the flash effect lasts for 2 seconds
      autoResize();
    } else {
      handleClarify();
      autoResize();
    }
  };

  useEffect(() => {
    if (isEditing) {
      const textarea = document.getElementById('textarea');
      if (textarea) {
        textarea.classList.add('flash-effect');
        setTimeout(() => {
          textarea.classList.remove('flash-effect');
        }, 2000);
        // További kód, mint például a fókuszálás és a lap tetejére görgetés
      }
    }
    // Itt add meg a függőségeket, amik hatással vannak az effekt futtatására
  }, [isEditing]);

  useEffect(() => {
    autoResize();
  }, [text]);

  if (showStarter) {
    return <StartScreen onBegin={handleBegin} />
  }

  return (
    <div className="main-content">
      {pageNumber !== 4 && <Header />}
      <BookmarkManager
        onBookmarkSelect={onBookmarkSelect}
      />
      <form onSubmit={handleSubmit} className={"container"}>
        {pageNumber === 1 &&
          <PageOne
            countryValue={country}
            stateValue={state}
            handleChange={handleChange}
            countryOptions={countryOptions}
            stateOptions={stateOptions}
            isLoadingCountries={isLoadingCountries}
            isLoadingStates={isLoadingStates}
          />
        }
        {pageNumber !== 1 &&
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "50px", paddingBottom: "20px", }}>
            <img src="/img/iuris_small_logo.jpg" alt="Logo" style={{ padding: "20px" }} />
            <div className="div_location">The legal issue related to <span className="boldtext">{country.value ? country.value : ''}{state.value ? ', ' + state.value : ''}</span></div>
          </div>}
        {pageNumber === 2 && <div>
          <PageTwo user_statement={text} handleChange={handleChange} textareaHeight={textareaHeight} />
        </div>}
        {pageNumber === 3 && (
          <PageThree
            user_statement={text}
            handleChange={handleChange}
            AIResponse={AISum}
            loading={loading}
            isEditable={isEditing}
            handleClarify={handleClarify}
            pageNumber={pageNumber}
            nextPage={nextPage}
            textareaHeight={textareaHeight}
            handleReset={handleReset}
            isEditing={isEditing}
            toggleEditing={toggleEditing}
          />
        )}

        {pageNumber === 4 && <div>
          <PageFour
            text={text}
            loading={loading}
            response={AIResponse}
            handleSubmit={handleSubmit}
            handleExport={handleExport}
            handleSaveBookmark={handleSaveBookmark}
            isEditable={isEditing}
            handleEditOrClarify={handleEditOrClarify}
            user_statement={text}
            handleChange={handleChange}
            handleEdit={handleEdit}
            regenerateResponse={regenerateResponse}
            responses={responses}
            currentRequest={currentRequest}
            isRegeneration={isRegeneration}
            textareaHeight={textareaHeight}
          />
        </div>}

        <div className={"returndiv-client"}>
          {pageNumber === 4 && <textarea
            name={"clientRequest"}
            value={clientRequest}
            cols={100}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            placeholder="[If you are looking for information other than the options provided above, please outline your requirements here]"
            style={{ height: clientTextAreaHeight }}
            id="client-textarea"
          />}
        </div>
        {/*pageNumber === 3 && !isEditing && (
          <div className="div_p3_correct_label">You can click on EDIT below to add or modify your statement</div>

        )*/}
        <div className="buttons-wrap">
          <div className={"buttons"}>
            {(pageNumber !== 1 && pageNumber < 3) && <BackButton handleClick={previousPage} pageNumber={pageNumber} />}
            {pageNumber === 1 && <ContinueButton
              handleClick={nextPage}
              pageNumber={pageNumber}
              disabled={!country || !state}
            />}

            {pageNumber === 2 && <ContinueButton
              handleClick={nextPage}
              pageNumber={pageNumber}
              disabled={!validText}
            />}



            {/*{pageNumber === 3 && <ContinueButton*/}
            {/*  handleClick={nextPage}*/}
            {/*  pageNumber={pageNumber}*/}
            {/*  disabled={!AISum.trim()}*/}
            {/*/>}*/}

            {pageNumber === 4 && (
              <div className="tooltip-container">
                <button
                  type="button"
                  name="submitClient"
                  className="btn_line_two"
                  onClick={handleSubmit}
                  disabled={loading || !clientRequest.trim()}
                  title={!clientRequest.trim() ? "We need a specific client request to process or choose from the other options" : ""}
                >
                  <FaArrowRight />Submit
                </button>
                {!clientRequest.trim() && <span className="tooltip-text">We need a specific client request to process or choose from the other options</span>}
              </div>
            )}

          </div>
          <div className="start-over-container">
            {pageNumber > 2 && <StartOverButton handleReset={handleReset} />}
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
}

const container = document.getElementById('start');
if (container) {
  const root = createRoot(container)
  root.render(<StartPage />)
}