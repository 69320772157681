import ContinueButton from "../Buttons/ContinueButton";
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import StartOverButton from "../Buttons/StartOverButton";
import { FaEdit, FaRedo, FaRedoAlt } from 'react-icons/fa'; // Example icons from 'react-icons'
//import assistantImage from '../public/img/assist.webp'; // Path to your assistant image


//export default function PageThree({ user_statement, handleChange, AIResponse, loading, isEditable }) {
export default function PageThree({
    user_statement,
    handleChange,
    AIResponse,
    loading,
    isEditable,
    handleClarify,
    pageNumber,
    nextPage,
    textareaHeight,
    handleReset,
    isEditing,
    toggleEditing // receive the new prop
}) {

    return (<div>
        {!loading &&
            <div className="ai-response">
                <div className="ai-assistant-container">
                    <img src="/img/assist.jpg" alt="Assistant" className="assistant-image" />
                    <div className="speech-bubble">
                        <ReactMarkdown remarkPlugins={[gfm]}>{AIResponse}</ReactMarkdown>
                    </div>
                </div>
            </div>}
        <div className={"editable"}>
            <div className="div_p3_statement_label">Your statement:</div>
            <textarea
                readOnly={!isEditable} // Szerkeszthetőség az isEditable prop alapján
                className={isEditable ? '' : 'tb_noteditable'}
                name={"text"}
                value={user_statement}
                cols={100}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                placeholder="[describe your situation in your own words]"
                style={{ height: textareaHeight }}
                id="textarea"
            />
            {loading && (
                <div className="spinner-container">
                    <div>
                        <img src="/img/spinner.gif" alt="Loading" className="spinner" />
                        <div className="spinner-text">Please wait, I am processing your request. It can take a while.</div>
                    </div>
                </div>
            )}
            <div className="confirmation-section">
                {user_statement.trim() && !loading && (
                    <div className="div_p3_correct_label">Engage with Miss Iuris to formulate a strong and detailed statement for your legal case. When you are satisfied with your statement than click ‘Continue’ to get insights related to your legal situation.</div>
                )}
                <div className="edit-buttons">
                    {pageNumber === 3 && !isEditing && (
                        <button type="button" className="edit-button" onClick={toggleEditing}>
                            <FaEdit /> Refine / Modify
                        </button>
                    )}
                    {isEditing ? (
                        // Render the "Clarify further" button when isEditing is true
                        <button className="btn_line_two clarify_button" type="button" onClick={toggleEditing} disabled={loading}>
                            <FaRedoAlt /> Answer to Miss Iuris
                        </button>
                    ) : (
                        // When not editing, show the usual buttons
                        <>
                            {/* <StartOverButton handleReset={handleReset} /> */}
                            <ContinueButton handleClick={nextPage} pageNumber={pageNumber} disabled={!AIResponse.trim() || loading} />
                            {/* Add other buttons here if needed */}
                        </>
                    )}
                </div>
            </div>
        </div>
        {/*    <div className={"returndiv"}>*/}
        {/*        <div className="div_p3_clarification">Clarification input: </div>*/}
        {/*        <textarea name={"clarify"} value={clarify} cols={100}*/}
        {/*            onChange={(e) => handleChange(e.target.name, e.target.value)} />*/}
        {/*    </div>*/}
    </div>
    );
}