import { FaArrowLeft, FaBackspace } from 'react-icons/fa';

export default function BackButton({ handleClick, isSubmitted }) {
    return <button type={'button'} onClick={() => {
        if (isSubmitted) {
            handleClick(2)
            return
        }
        handleClick()
    }}>{isSubmitted ? <> <FaArrowLeft />Start Over</> : <> <FaArrowLeft />Back</>}</button>
}