// Modal.js
import React from 'react';
import Modal from 'react-modal';
import "../App.css"

Modal.setAppElement('#root');

const CustomModal = ({ isOpen, onRequestClose, onOkClick }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Example Modal"
      className="customModalContent"
      overlayClassName="customModalOverlay"
    >
      <div className="modalBody">
        <div className='modalContainer'>
          <h1>Disclaimer</h1>
          <p>The information provided by our automated service is intended to
            assist in the preparation of your legal case. It does not constitute
            legal advice, nor does it establish a client-lawyer relationship. We
            strongly recommend consulting with a qualified legal professional
            before making any decisions based on the information provided.</p>
          <p>We are not responsible for any loss, injury, claim, liability, or
            damage related to your use of this site or any site linked to this
            site, whether from errors or omissions in the content of our site or
            any other linked sites, from the site being down or from any other
            use of the site.</p>
        </div>
        <button
          className="okButton"
          onClick={() => {
            onOkClick();
            onRequestClose();
          }}
        >
          OK
        </button>
      </div>
    </Modal>
  );
};

export default CustomModal;
